<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Blog</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <section class="blog" style="margin-top: -50px;">
        <div class="container pt-0" data-aos="fade-up">
            <div class="row g-5">
                <div class="col-lg-12">
                    <article class="blog-details">
                        <div class="post-img">
                            <img src="assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
                        </div>
                        <h2 class="title">Dolorum optio tempore voluptas dignissimos cumque fuga qui quibusdam quia</h2>
                        <div class="meta-top">
                            <ul>
                                <li class="d-flex align-items-center"><i class="bi bi-person"></i> <a href="">John
                                        Doe</a></li>
                                <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <a href=""><time
                                            datetime="2020-01-01">Jan 1, 2022</time></a></li>
                            </ul>
                        </div>
                        <div class="content">
                            <p>
                                Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi
                                praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.
                                Et eveniet enim. Qui velit est ea dolorem doloremque deleniti aperiam unde soluta. Est
                                cum et quod quos aut ut et sit sunt. Voluptate porro consequatur assumenda perferendis
                                dolore.
                            </p>
                            <p>
                                Sit repellat hic cupiditate hic ut nemo. Quis nihil sunt non reiciendis. Sequi in
                                accusamus harum vel aspernatur. Excepturi numquam nihil cumque odio. Et voluptate
                                cupiditate.
                            </p>
                            <p>
                                Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi
                                praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.
                                Et eveniet enim. Qui velit est ea dolorem doloremque deleniti aperiam unde soluta. Est
                                cum et quod quos aut ut et sit sunt. Voluptate porro consequatur assumenda perferendis
                                dolore.
                            </p>
                            <p>
                                Sit repellat hic cupiditate hic ut nemo. Quis nihil sunt non reiciendis. Sequi in
                                accusamus harum vel aspernatur. Excepturi numquam nihil cumque odio. Et voluptate
                                cupiditate.
                            </p>
                            <p>
                                Similique neque nam consequuntur ad non maxime aliquam quas. Quibusdam animi
                                praesentium. Aliquam et laboriosam eius aut nostrum quidem aliquid dicta.
                                Et eveniet enim. Qui velit est ea dolorem doloremque deleniti aperiam unde soluta. Est
                                cum et quod quos aut ut et sit sunt. Voluptate porro consequatur assumenda perferendis
                                dolore.
                            </p>
                            <p>
                                Sit repellat hic cupiditate hic ut nemo. Quis nihil sunt non reiciendis. Sequi in
                                accusamus harum vel aspernatur. Excepturi numquam nihil cumque odio. Et voluptate
                                cupiditate.
                            </p>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </section>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Save click')">Close</button>
</div>