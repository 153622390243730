<app-navigation></app-navigation>
<!---->
<section class="services custom_top_margin">
    <div class="container-fluid" data-aos="fade-up">
        <div class="section-header mt-0">
            <h3 class="blue">Samatva Clinic for Speech-Language Therapy and Occupational Therapy- JP Nagar</h3>
        </div>

        <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_01.JPG"
                data-responsive="assets/img/branches/jpnagar/original/JP_01.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_01.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_02.JPG"
                data-responsive="assets/img/branches/jpnagar/original/JP_02.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_02.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_03.JPG"
                data-responsive="assets/img/branches/jpnagar/original/JP_03.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_03.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_04.jpg"
                data-responsive="assets/img/branches/jpnagar/original/JP_04.jpg" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_04.jpg" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_05.jpg"
                data-responsive="assets/img/branches/jpnagar/original/JP_05.jpg" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_05.jpg" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_06.jpg"
                data-responsive="assets/img/branches/jpnagar/original/JP_06.jpg" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_06.jpg" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/jpnagar/original/JP_07.jpg"
                data-responsive="assets/img/branches/jpnagar/original/JP_07.jpg" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/jpnagar/thumbnail/JP_07.jpg" />
            </a>
        </lightgallery>

        <div class="row pt-5">
            <div class="col-md-8 text-left">
                <div class="m-3 p-1">
                    <h3 class="blue"><u>Address</u></h3>
                    <p><b>No. 720, 24th Main Rd, Near Bank of Maharashtra, KR Layout, Phase 6, JP nagar, Bengaluru-78
                    </b></p>
                    <p><b>Contact: 829544422 / 7829644422 / 7892625828</b></p>
                </div>
    
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <img class="img-responsive text-center" width="150"
                    src="assets/img/branches/qrcodes/jpnagar.PNG" />
                </div>
            </div>
        </div>
    </div>
</section>
<!---->
<app-footer></app-footer>