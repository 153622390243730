import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
 
@Component({
  selector: 'app-celebrations',
  templateUrl: './celebrations.component.html',
  styleUrls: ['./celebrations.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CelebrationsComponent implements OnInit {

  settings = { 
    counter: false,
    plugins: [lgZoom]
  };

  constructor() { }

  thumbnaliData=  [
    {
      "thumbName":"assets/gallery/events/thumbnail/01.jpg",
      "originalBame":"assets/gallery/events/original/01.jpg"
    }
  ]

  ngOnInit(): void {
  }

  
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };
 
}
