import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { BlogsDetailsComponent } from '../blogs-details/blogs-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  public user = {
    name: 'Izzat Nadiri',
    age: 26
    }

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    AOS.init();
  }

  readMoreBlog(){
    //alert("here");
    const modalRef = this.modalService.open(BlogsDetailsComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.user = this.user;
  }

}
