<app-navigation></app-navigation>
<!---->

<div class="" data-aos="fade-up" data-bs-interval="5000">
    <!---->
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4">
            </button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5">
            </button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img class="d-block w-100" src="assets/img/home_page/slider 1.jpg" alt="">
            </div>
            <div class="carousel-item">
                <img class="d-block w-100" src="assets/img/home_page/slider 2.jpg" alt="">
            </div>
            <div class="carousel-item">
                <img class="d-block w-100" src="assets/img/home_page/slider 3.jpg" alt="">
            </div>
            <div class="carousel-item">
                <img class="d-block w-100" src="assets/img/home_page/Slider 4_new.png" alt="">
            </div>
            <div class="carousel-item">
                <img class="d-block w-100" src="assets/img/home_page/slider 5.jpg" alt="">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <!-- <section id="hero" class="hero carousel  carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">
        
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-lg-10 col-md-10 h-50">
                            <img src="assets/img/home_page/slider_01.jpg" alt="" class="img-fluid img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev" href="#hero" role="button" data-bs-slide="prev">
            <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
        </a>

        <a class="carousel-control-next" href="#hero" role="button" data-bs-slide="next">
            <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
        </a>

        <ol class="carousel-indicators"></ol>

    </section> -->
    <!---->

    <!--About-->
    <section id="cta" class="cta mt-5">
        <div class="container" style="text-align: justify;" data-aos="zoom-out">
            <div class="row g-5">
                <div
                    class="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                    <h3><em>Samatva </em></h3>
                    <p style="font-weight: 400">
                        Samatva stands as a testament to the power of passion, expertise, innovation and unwavering
                        dedication for building a brighter future for Neurodiverse Children & Adults. At Samatva, we
                        take pride in our Evidence-Based Holistic Practices towards therapy, encompassing a wide range
                        of services including Speech-Language Therapy, Occupational Therapy, Physiotherapy, Talk – Tank
                        (Social Skills Group), School Readiness Program and National Institute of Open Schooling (NIOS).
                    </p>
                    <h4 style="color: #485664;">Our Vision:</h4>
                    <p style="font-weight: 400">                 
                        To be a global inter-disciplinary service provider with a pragmatic therapeutic care for
                        individuals with special needs and their families.</p>
                    <h4 style="color: #485664;">Our Mission:</h4>
                    <ul>
                        <li>
                            <p style="font-weight: 400">
                                Ensure the best outcomes through evidence-based practices by embracing and empowering our rehab
                                professionals.
                            </p>
                        </li>
                        <li>
                            <p style="font-weight: 400">
                                Serve diverse population and offer world class therapy both in-person and tele modalities.
                            </p>
                        </li>
                        <li>
                            <p style="font-weight: 400">Involve and educate the caregivers in our therapy processes and enhance the quality of the life
                                of individuals with special needs
                            </p>
                        </li>
                    </ul>    
                    <a class="cta-btn align-self-start" style="text-decoration: none;" href="#wcu">Why choose us?</a>
                </div>

                <div class="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                    <div class="img">
                        <img src="assets/img/home_page/about_samavth.jpg" alt="" class="img-fluid">
                    </div>
                </div>

            </div>

        </div>
    </section>
    <!---->
    <!---->
    <section id="wcu" class="faq">
        <div class="container-fluid" data-aos="fade-up">
            <div class="row gy-4">
                <div class="col-lg-5 align-items-stretch img rounded"
                    style='background-image: url("assets/img/home_page/why_choose_us.jpg");'>&nbsp;
                </div>
                <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">
                    <div class="content px-xl-5" style="font-weight: 400">
                        <h3>Why choose <strong>us?</strong></h3>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <!--fst-italic-->
                            <p class="fs-5 ms-1">
                                State of the Art Facilities
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Integrated Therapeutic Services under One Roof
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Supportive, Caring and Safe Environment
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                A Team of Certified, Licenced, Skilled and Experienced Practitioners
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Application of Specialised and Functional Therapeutic Approaches
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Forte of Result Oriented Therapy
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Individual & Group Therapy Sessions
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Longer Hours of Intensive Intervention
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                Emphasis on Family Dynamics & Relationship
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-1">
                            <i class="bi bi-check2 blue fs-6"></i>
                            <p class="fs-5 ms-1">
                                On-going Family Guidance and Support
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!---->
    <!--Testimonials-->

    <section id="testimonials" class="testimonials">
        <div class="container-fluid" data-aos="fade-up">
            <div class="testimonials-slider swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="testimonial-item">
                            <h2>Vasavi Bolli</h2>
                            <div class="stars">
                                <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                <span>
                                    Samatva is best place for all the special needs! I sent my son for 6 months and seen
                                    noticeable change in him.
                                    A special thank you for Neeraja mam, Susan mam, Radika mam and Daniel sir helping my
                                    son to cop up on each step and sharing the tips each day
                                </span>
                                <span>
                                    They discuss the updates what was done at session what to practice and how to practice with him.
                                </span>
                                <span>
                                    Highly recommendable
                                </span>
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item">
                            <h2>Padmaja Rao</h2>
                            <div class="stars">
                                <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                Hello Team Samatva,
                                We are so glad to have known your team at the right age for our Son who was struggling
                                with his speech. Thanks to Dr. Prabhakar Murthy for referring us to you.
                                The very first meeting with Ms. Radhika, gave us confidence that our son is in the best
                                hands and that they would take care of his speech very well, and it is successful 6
                                months journey with them which had instilled a great lot of Confidence & Clarity in our
                                sons speech. They went an extra mile in consulting other speech therapists which we
                                highly appreciate. They really take that extra step to each child to give their best.
                                Thank you is a small word which we can offer to you Team. I would like to mention Dr.
                                Neeraja for her continuous support, Ms. Radhika, Ms. Susan & Ms. Aishwarya for their
                                unending love, support, flexibility and Professionalism they show.
                                Our Son has blossomed so well and we are extremely happy parents.
                                Thank You!
                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item">
                            <h2>Vijetha MM</h2>
                            <div class="stars">
                                <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                Team SAMATVA,
                                I wanted to take a moment to express my sincere gratitude for the wonderful work that
                                you do. Your dedication and commitment to helping children like my son reach their full
                                potential is truly admirable and inspiring.
                                Your expertise in child development and your skills in providing therapy have been
                                invaluable in helping so kids overcome challenges and achieve his goals. Big KUDOS to
                                your patience, kindness, and compassionate approach that has made a positive impact on
                                our lives and many more children and their families. Your hard work and tireless efforts
                                have not gone unnoticed. Your work is truly important, and I appreciate your
                                contributions to making our parents' lives easy and rewarding.
                                Our son is so fond of all the teachers at SAMATVA, not without mentioning the names of
                                Ms. Aishwarya, Ms. Susan, Ms. Aparna, and Mr. Daniel. That really shows the love you
                                guys have showered on him.
                                Ms. Neerja and Ms. Radhika - Thank you for building such a great place for this society
                                and always being available to parents like us!
                                Once again, thank you for your dedication and commitment to helping kids. You are truly
                                making a difference in the world, one child at a time.

                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="testimonial-item">
                            <h2>Sahana V V</h2>
                            <div class="stars">
                                <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                            </div>
                            <p>
                                <i class="bi bi-quote quote-icon-left"></i>
                                It is a scary feeling to see your child having a difficulty at anything in life, for my
                                baby it was speech. What to do ? When to do it? How to do it? Are some of the many
                                questions that run through our mind and Samatva helped answer all of that.

                                They are very professional, they give you a clear understanding of where the problem is
                                and how to remediate it. They have fantastic techniques to accelerate the development
                                and they also give good feedbacks to the parents. They are amazing with the kids, my son
                                is more than happy to go there. All in all it is a great experience, am a happy mom who
                                went from "why isn't he talking yet" to recently telling my son to keep quiet.

                                <i class="bi bi-quote quote-icon-right"></i>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="swiper-pagination"></div>
            </div>

        </div>
    </section>
    <!--Testimonials-->

    <!--FAQ-->
    <section id="faq" class="faq">
        <div class="container-fluid" data-aos="fade-up">

            <!--Image-->
            <div class="row">
                <div class="col-md-12 text-center">
                    <h1>Patient Induction Process</h1>
                </div>
                <div class="col-md-12">
                    
                    <img class="d-block w-100" src="assets/img/home_page/pip.jpg" alt="">
                </div>
            </div>
            <!--
            <div class="row gy-4">
                <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">
                    <div class="content px-xl-5">
                        <h3>Frequently Asked <strong>Questions</strong></h3>
                        <p>
                        </p>
                    </div>
                    <div class="accordion accordion-flush px-xl-5 " style="color: #485664 !important;" id="faqlist">
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-1">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    What services does my child need?
                                </button>
                            </h3>
                            <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold"> Following a detailed assessment, you will be counselled
                                        regarding the findings of
                                        the assessment and recommended specific therapeutic approaches based on your
                                        child's
                                        need.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-2">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    How many sessions will I need to attend?
                                </button>
                            </h3>
                            <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">Most children/adults with communication disorder generally
                                        require long term
                                        intervention. However, you can speak to your therapists regarding the prognosis
                                        of
                                        therapy for your child/ family member as it varies from individual to individual
                                        depending on various factors.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-3">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    How do I fix my appointment on a daily basis?
                                </button>
                            </h3>
                            <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">Once you have completed your assessment, you will receive a
                                        WhatsApp message/call
                                        one day prior to your therapy appointments which needs to be confirmed by you.
                                        Kindly note you will receive a message prior to every therapy appointment which
                                        would need to be confirmed by you everytime.</p>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-4">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    Do you provide individual and group sessions?
                                </button>
                            </h3>
                            <div id="faq-content-4" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">We have both individual (one-one) as well as group therapy
                                        sessions. We generally
                                        recommend individual sessions, however based on the individual's need, group
                                        sessions are organized occasionally.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-5">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    What is the time duration for each session?
                                </button>
                            </h3>
                            <div id="faq-content-5" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">Each session is taken for a total duration of 45 minutes. Wherein
                                        40 minutes session
                                        is carried out and the last 5 minutes, the therapist would be briefing you
                                        regarding
                                        the session feedback and home training activities.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-6">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    How do I track my child's progress?
                                </button>
                            </h3>
                            <div id="faq-content-6" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">We generally recommend the parents to maintain a diary to note
                                        down goals &
                                        activities to be carried as home training to track progress. We have monthly
                                        meeting
                                        with parents to discuss on progress and further goal planning.</p>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-7">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    Will my child have a fixed therapist?
                                </button>
                            </h3>
                            <div id="faq-content-7" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">Yes! Your child will be assigned a fixed therapist. However we
                                        believe, every child
                                        should be flexible to listen, learn and interact to different individuals and
                                        environments. So based on his/ her need, your child might be assigned to
                                        different
                                        therapists or in the case of unavailability of the particular therapist.</p>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-8">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    Who else can I contact regarding concerns about my child?
                                </button>
                            </h3>
                            <div id="faq-content-8" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">You can first contact your therapist regarding your concerns.
                                        Further queries/issues
                                        can be discussed with the Clinical Head as well as the Directors.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-9">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    How can I get in touch with my therapist online?
                                </button>
                            </h3>
                            <div id="faq-content-9" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">You can message the Samatva admin regarding your feedbacks and
                                        queries. The same
                                        would be forwarded to the concerned therapist. In case you need to speak to the
                                        therapist, put forward your request with the admin and you will be contacted by
                                        your
                                        therapist based on their availability.</p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item" data-aos="fade-up" data-aos-delay="200">
                            <h3 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-10">
                                    <i class="bi bi-question-circle question-icon"></i>
                                    How can I avail a report regarding my child's progress?
                                </button>
                            </h3>
                            <div id="faq-content-10" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                                <div class="accordion-body">
                                    <p class="fw-bold">You can put forward your request to the Samatva admin/therapist.
                                        Kindly note your
                                        report can be issued only within 5 working days.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img rounded"
                    style='background-image: url("assets/img/faq.jpg");'>&nbsp;
                </div>
            </div>
        -->
        </div>
        
    </section>
    <!-- End F.A.Q Section -->
</div>
<!---->
<app-footer></app-footer>