<app-navigation></app-navigation>
<!---->
<section id="team" class="team custom_top_margin">
    <div class="container" data-aos="fade-up">
        <div class="section-header">
            <h2 class="blue">Our Founders & Directors</h2>
        </div>
        <div class="row about g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/team/directrs.jpg" class="rounded  img-fluid" alt="">
                    <!-- <p class="mt-3 blue" style="text-align: justify;">
                        <em>Samatva</em> owes its existence to its visionary founders, <em><u>Dr Neeraja Karthi and Ms.
                                Megha Jorapur.</u></em> Their
                        unwavering commitment to creating a nurturing environment where individuals of all abilities
                        could
                        thrive, led to the birth of this remarkable center.
                    </p> -->

                </div>
            </div>
            <div class="col-lg-7" style="text-align: justify;">
                <p style="text-align: justify;">
                    <em>Samatva</em> owes its existence to its visionary founders, <em><u>Dr Neeraja Karthi and Ms.
                            Megha M Jorapur</u></em>.
                    The unwavering commitment of these two women entrepreneurs to creating a nurturing environment
                    wherein persons of all abilities could thrive, led to the birth of this remarkable center in
                    2016. They left their ideal and secure job and built SAMATVA from scratch intending to bring in
                    a difference and change in the lives of many neurodiverse individuals. With time they included
                    all the services under one roof that became a boon for many. The birth of SAMATVA was driven by
                    their passion to bridge the gaps in rehabilitative services in the South of Bengaluru.
                </p>
                <p style="text-align: justify;">
                    Dr. Neeraja & Ms. Megha, as Directors are licensed practitioners registered under Rehabilitation
                    Council of India (RCI) (Governing body of Speech-language Pathologists and Audiologists in India)
                    and Life members of Indian Speech and Hearing Association (ISHA) and Indian Speech and Hearing
                    Association (Bengaluru Chapter) (Professional and Scientific Associations in India). They have been
                    associated since 2002 as pals and successfully practicing in the profession since 2004. They believe
                    in embracing all forms of communication and multi-sensorial therapy for the neurodiverse population
                    and making them enjoyable, meaningful and effective for its stakeholders. They are the masterminds
                    behind the global recognition that SAMATVA has today with their excellent clinical and
                    administrative capacities. With a vision to expand and collaborate in order to serve a huge number
                    of stakeholders with an uncompromising quality, they constantly strive to make SAMATVA a renowned
                    brand in the field of Rehabilitation. They are very humble leaders offering valuable insights for
                    the present generation of therapists.
                </p>
                <!-- <p>
                    <em>Dr. Neeraja & Ms. Megha,</em> as directors are licensed practitioners registered under
                    Rehabilitation
                    Council of India (RCI) (Governing body of Speech-language Pathologists and Audiologists in India)
                    and members of Indian Speech and Hearing Association (ISHA) and Indian Speech and Hearing
                    Association (Bengaluru Chapter) (Professional and Scientific Associations in India). They have been
                    successfully practicing in the profession since 2004.
                </p>
                <p>
                    <em>Dr. Neeraja Karthi</em> holds a doctorate in Speech Language Pathology from the National
                    Institute of
                    Mental Health and Neurosciences (NIMHANS). She is a very enthusiastic and a driven Speech Language
                    Pathologist who actively mentors her team of professionals, parents & caregivers. She believes in a
                    family centered approach and advocates result driven therapeutic intervention. She is a trained and
                    certified Hanen Therapist for It takes two to talk. As a PROMPTs for Restructuring Oral Muscular
                    Phonetic Targets (PROMPT) trained therapist, she works on oro-motor planning, programming and
                    execution of sounds required for Speech and Language skills. She is also certified in providing the
                    DIR/Floortime approach.
                </p>
                <p>
                    <em>Ms Megha Jorapur</em> holds a Masters in Audiology and Speech Language Pathology from Manipal
                    University.
                    She is very passionate and dedicated towards building the team towards accomplishing success with
                    all their cases. She has inculcated immense interest in intervention of children with developmental
                    delays, apraxia, autism and sensory processing disorders. In pursuit of the same, she is a certified
                    Oral Placement Therapist (Level 2) and trained in DIR/Floortime approach. She is also a Hanen
                    therapist for It takes two to talk.
                </p> -->
            </div>
        </div>
        <!---->
        <div class="section-header p-2">
            <h2 class="blue">Clinical Team</h2>
        </div>
        <div class="row gy-5 ps-5 pe-5">
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Neeraja.jpg" class="img-fluid" alt="">
                    </div>
                    <!-- <div class="member-info">
                        <h4>Dr Neeraja Karthi</h4>
                        <p>Founder & Director,</p>
                        <p>Speech Language Pathologist</p>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-9 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div>
                    <h5><em>Dr Neeraja Karthi</em></h5>
                    <h6><em>Founder & Director</em></h6>
                    <h6><em>Speech Language Pathologist</em></h6>
                </div>
                <div class="pt-2">
                    <p style="text-align: justify;">
                        <em>Dr. Neeraja Karthi</em> is a highly skilled and experienced Speech - Language Pathologist.
                        She holds a
                        Doctorate in the field of Speech- Language Pathology from the National Institute of Mental
                        Health
                        and Neurosciences (NIMHANS). She is a very enthusiastic and a driven Speech-Language Pathologist
                        who
                        actively mentors her team of professionals, parents & caregivers. She believes in a family
                        centered
                        approach and advocates result driven therapeutic intervention. She is a trained and certified
                        Hanen
                        Therapist for “It takes two to talk”. As a PROMPTs for Restructuring Oral Muscular Phonetic
                        Targets
                        (PROMPT) trained therapist, she works on oro-motor planning, programming and execution of sounds
                        required for Speech and Language skills. She is also certified in providing the DIR/Floor-time
                        approach. With a demonstrated history of clinical, teaching and research experience closer to a
                        decade at NIMHANS (A Premier National institute for Neurogenic and Mental Health Patients) and
                        other
                        national /international clinical institutes, her expertise and guidance in pediatric and
                        adult-neuro
                        rehabilitation bring value add-ons to those individuals seeking these services.
                    </p>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Megha.jpg" class="img-fluid" alt="">
                    </div>
                    <!-- <div class="member-info">
                        <h4>Ms Megha M Jorapur</h4>
                        <p>Founder & Director,</p>
                        <p>Speech Language Pathologist</p>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-9 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div>
                    <h5><em>Ms Megha M Jorapur</em></h5>
                    <h6><em>Founder & Director</em></h6>
                    <h6><em>Speech Language Pathologist</em></h6>
                </div>
                <div class="pt-2">
                    <p style="text-align: justify;">
                        <em>Ms Megha Jorapur</em> holds a Masters in Audiology and Speech Language Pathology and an
                        alumnus of Manipal Academy of Higher Education, Manipal University. She worked for a decade in
                        various esteemed institutions across India as Clinical Head, Academician & also as a full
                        fledged Private Practitioner. She is a very passionate and dedicated towards building the team
                        and accomplishing success with all their families. She has inculcated immense interest in
                        intervention of children with Developmental Delays, Apraxia, Autism and Sensory Processing
                        Disorders. In pursuit of the same, she is a certified Oral Placement Therapist (Level 1 & 2) and
                        trained in DIR/Floortime approach. Her expertise in OPT helped to raise the bar of the
                        therapeutic practices at SAMATVA. She is also a Hanen therapist for “It takes two to talk”. Her
                        deep connection and compassion for her stake holders has transformed many lives. She is a
                        versatile Speech - language Pathologist who understands the nuances of the child’s challenges.
                        She motivates and shapes the team with her active listening, adaptability, creativity and
                        leadership skills.
                    </p>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Radhika.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Radhika Suresh</h4>
                        <p>Head of Operations & Clinical Head, </p>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Susan.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Susan Abi Thomas</h4>
                        <p>Team Lead & Clinical Head</p>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Daniel.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Mr Daniel J Anthony</h4>
                        <p>Head of Occupational Therapy</p>
                        <p> Occupational Therapist</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Sanjana.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Sanjana Manjunath</h4>
                        <p>Coordinator- NIOS & Group Intervention Program</p>
                        <p>Special Educator</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Harshitha.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Harshitha Gurkar H N</h4>
                        <p>Clinical Head-Koramnagala </p>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Adithya.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Adithya K Madhu</h4>
                        <p>Branch Incharge - BN</p>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Rima.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Rima Ravi</h4>
                        <p>Social Skill Group Incharge</p>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Jeena.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Jeena Mariam</h4>
                        <p>Speech Language Pathologist- Koramangala</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Nikhil.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Mr Nikhil Mon Sunny</h4>
                        <p>Speech Language Pathologist- RR Nagar</p>
                    </div>
                </div> 
            </div>-->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/amy.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Amy Ann George</h4>
                        <p>Speech Language Pathologist- EC</p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Naina Hussain.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Naina Hussain </h4>
                        <p>Speech Language Pathologist- JP Nagar</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Surabhi.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Surabhi S Holla </h4>
                        <p>Occupational Therapist- RR Nagar</p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Ranjitha.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Ranjitha </h4>
                        <p>Physiotherapist/Sensori-motor Therapist- JP Nagar</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/radhika Spl Education.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms R Radhika </h4>
                        <p>Assistant to Special Educator: Group Intervention Program</p>
                    </div>
                </div>
            </div> -->
           
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Pankaj Das.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Pankaj Das</h4>
                        <p>Behavioural Therapist- RR Nagar</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Benet B Benny.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Benet B Benny</h4>
                        <p>Occupational Therapist- Basaveshwar Nagar</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Bhavika Verma.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Bhavika Verma </h4>
                        <p>Occupational Therapist- EC</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Naadia Khateeja.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Nadia Khateeja </h4>
                        <p>Speech Language Pathologist- RR Nagar</p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Pradeep Raj.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Mr Pradeep Raj</h4>
                        <p>Occupational Therapist</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Vignesh Sellamuthu.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Mr Vignesh</h4>
                        <p>Occupational Therapist</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Dona Benny.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Dona </h4>
                        <p>Speech - Language Pathologist </p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Jahnavi H.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Jhanavi H </h4>
                        <p>Speech - Language Pathologist </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Amrutha John.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Amrutha John </h4>
                        <p>Language , Communication, Remedial  and Behavioral Therapist </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Nazia Javed.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Nazia Javed </h4>
                        <p>Language , Communication, Remedial  and Behavioral Therapist </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Sanjana.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Sanjana</h4>
                        <p>Speech Language Pathologist</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-header p-2">
            <h2 class="blue">Non Clinical Team</h2>
        </div>
        <div class="row gy-5 ps-5 pe-5">
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Devi_NC.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Devi Durga Dommeti </h4>
                        <p>Schedule Manager & Secretary to the Director</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Chanadana.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Chanadana </h4>
                        <p>Front Office Admin</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Madhuri R.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Madhuri R</h4>
                        <p>Accountant</p>
                    </div>
                </div>
            </div> -->
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Latha.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Latha</h4>
                        <p>Front Office Admin - RR Nagar</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/sangeetha_NC.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Sangeetha</h4>
                        <p>Front Desk Office Executive- BN</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Ambika_NC.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Ambika N</h4>
                        <p>Front Desk Office Executive- JP Nagar</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Manasa.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Manasa </h4>
                        <p>Admin / Accounts  Incharge
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/Sunitha_NC.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Sunitha G C</h4>
                        <p>Clinical Assistant / Inhouse Management</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/lakshmi 2.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Lakshmi Devi </h4>
                        <p>Support Staff</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 d-flex" data-aos="zoom-in" data-aos-delay="200">
                <div class="team-member">
                    <div class="member-img">
                        <img src="assets/img/team/regina.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="member-info">
                        <h4>Ms Regina S </h4>
                        <p>Support Staff</p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!---->
<app-footer></app-footer>