<app-navigation></app-navigation>
<!---->
<section id="about" class="about custom_top_margin">
    <div class="container " data-aos="fade-up">

        <div class="section-header">
            <h2 class="blue">Careers</h2>
        </div>
        <div class="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/join+our+team.png" class="img-fluid rounded" alt="">
                </div>
            </div>
            <div class="col-lg-7">
                <!-- <h3 class="pt-0 pt-lg-5">Some content goes here</h3> -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active ps-2 pe-2 blue" data-bs-toggle="pill" href="#tab1">Why join us?</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ps-2 pe-2 blue" data-bs-toggle="pill" href="#tab2">Desired Qualifications &
                            Skills</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ps-2 pe-2 blue" data-bs-toggle="pill" href="#tab3">Who can apply?</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link ps-2 pe-2 blue" data-bs-toggle="pill" href="#tab4">How to apply?</a>
                    </li>
                </ul>
                <!-- Tabs -->
                <!-- Tab Content -->
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="tab1">
                        <div class="d-flex align-items-center mt-4">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Opportunity to work with both Paediatric and Adult Population
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-4">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Excellent clinical learning and exposure
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5 ">
                                Rigorous and on-going hands-on training on specialised therapy techniques
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Multi-disciplinary & culturally diverse team
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5 ">
                                Monthly CLs & other holidays
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5 ">
                                Paid Birthday Offs
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Performance- Based Rewards
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Excellent salary package
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Unique Career paths and wide scope for promotions
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Team gatherings & cultural transformation activities
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Understanding and approachable superiors
                            </p>
                        </div>
                        <div class="d-flex align-items-center mt-2">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5">
                                Supportive, enthusiastic & a healthy working environment
                            </p>
                        </div>
                    </div>
                    <!---->
                    <div class="tab-pane fade show" id="tab2">
                        <div class="d-flex align-items-center mt-4">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5 ">Qualification:</p>
                        </div>
                        <p class="ms-5 pt-2">1. SLPs: BASLP/ MASLP/ MSc-SLP</p>
                        <p class="ms-5">2. OT: BOT/ MOT</p>
                        <p class="ms-5">3. Special Educator: BSED/ MSED/ B.Ed
                        </p>
                        <div class="d-flex align-items-center mt-4">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5  ">Desirable competencies: </p>
                        </div>
                        <p class="ms-5 pt-2  ">- Excellent spoken & written communication skills
                        </p>
                        <p class="ms-5 pt-2  ">- As a team player & demonstrate team ownership skills
                        </p>
                        <p class="ms-5 pt-2  ">- Versatile Personality</p>
                        <p class="ms-5 pt-2  ">- Work ethics & commitment</p>
                        <p class="ms-5 pt-2  ">- Discipline, sincerity & punctuality</p>
                    </div>
                    <!---->
                    <div class="tab-pane fade show" id="tab3">

                        <div class="d-flex align-items-center mt-4">
                            <i class="bi bi-check2"></i>
                            <p class="fs-5  ">Passionate, Creative & Enthusiastic Speech Language Pathologists,
                                Occupational Therapists & Educators</p>
                        </div>
                    </div>
                    <!---->
                    <div class="tab-pane fade show" id="tab4">
                        <div class="align-items-center mt-4" style="text-align: center;">
                            <p class="fs-4">Send your resumes to</p>
                            <p class="fs-4 blue">
                                <span style="cursor: pointer;"><i class="bi bi-envelope-at-fill"></i>
                                    samatvalearning@gmail.com</span>
                            </p>
                            <p class="fs-4 blue">
                                <span style="cursor: pointer;"><i class="bi bi-envelope-at-fill"></i>
                                    samatvaoffice@gmail.com</span>
                            </p>
                            <p class="fs-4">Or Contact</p>
                            <p class="fs-4 blue">
                                <span class="pt-3"><i class="bi bi-phone-fill"></i> 7829544422 / 7829644422</span>
                            </p>
                        </div>
                    </div>
                    <!---->
                </div>
            </div>
        </div>
    </div>
</section>
<!---->
<app-footer></app-footer>