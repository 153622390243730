<!-- ======= Footer ======= -->
<!-- <footer class="footer pt-5" style="margin-left:-12px; margin-right: -12px;"> -->

<footer class="footer pt-5">
    <div class="footer-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-6">
                    <div class="footer-info">
                        <p class="fs-5 mb-3">Samatva Clinic for Speech-Language Therapy and Occupational Therapy</p>
                        <h4>( A Unit of Samathva  Rehabs Private Limited, Bangalore )</h4>
                        <p class="fs-6">
                            #20 Sri Krishna Dhama, 3rd Main
                            5th Cross Road
                            Kenchenahalli, BHEL Layout, RR Nagar
                            Bengaluru, Karnataka 560098,
                            India <br>
                            <br><br>
                            <strong>Phone:</strong> +91 78926 25828 / 78296 44422 / 78295 44422 / <br>
                            <strong>Email:</strong> samatvalearning@gmail.com / samatvaoffice@gmail.com<br>
                        </p>
                    </div>
                </div>
                <!-- <div class="col-lg-1 col-md-1 footer-links">
                </div> -->
                <div class="col-lg-3 col-md-5 footer-links">
                    <h4 class="fs-5">Our Branches </h4>
                    <ul class="fs-6">
                        <li><i class="bi bi-chevron-right"></i> <a routerLink="/rajarajeshwari-nagar">Rajarajeshwari Nagar (Head Office)</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a routerLink="/basaveshwar-nagar">Basaveshwar Nagar</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a routerLink="/electronic-city">Electronic City</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a routerLink="/jp-nagar">JP Nagar</a></li>
                        <li><i class="bi bi-chevron-right"></i> <a routerLink="/koramangala">Koramangala</a></li>
                    </ul>
                    
                </div>
                <div class="col-lg-4 col-md-6 footer-links">
                    <h4 class="fs-5">Useful Links</h4>
                    <ul class="fs-6">
                        <li>
                            <i class="bi bi-chevron-right"></i>
                            <a routerLink="/careers">Careers</a>
                        </li>
                        <li>
                            <i class="bi bi-chevron-right"></i>
                            <a routerLink="/events">Events</a>
                        </li>
                        <li>
                            <i class="bi bi-chevron-right"></i>
                            <a routerLink="/celebrations">Celebrations</a>
                        </li>
                        <li>
                            <i class="bi bi-chevron-right"></i>
                            <a routerLink="/team">Team</a>
                        </li>
                        <!-- <li>
                            <i class="bi bi-chevron-right"></i>
                            <a routerLink="/contactus">Book an appointment </a>
                        </li> -->
                        <!-- <li><i class="bi bi-chevron-right"></i> <a href="#">Home</a></li>
                            <li><i class="bi bi-chevron-right"></i> <a href="#">About us</a></li>
                            <li><i class="bi bi-chevron-right"></i> <a href="#">Services</a></li>
                            <li><i class="bi bi-chevron-right"></i> <a href="#">Terms of service</a></li>
                            <li><i class="bi bi-chevron-right"></i> <a href="#">Privacy policy</a></li> -->
                    </ul>
                </div>
                <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
                        <h4>Our Newsletter</h4>
                        <p>Some content goes here</p>
                        <form action="" method="post">
                            <input type="email" name="email"><input type="submit" value="Subscribe">
                        </form>

                    </div> -->

            </div>
        </div>
    </div>

    <div class="footer-legal text-center">
        <div
            class="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
            <div class="d-flex flex-column align-items-center align-items-lg-start">
                <div class="copyright">
                    &copy; Copyright <strong><span>Samathva   Rehabs Private Limited</span></strong>. All Rights Reserved
                </div>
                <!-- <div class="credits">
                        Designed by <a href=""></a>
                    </div> -->
            </div>
            <div class="social-links order-first order-lg-last mb-3 mb-lg-0">
                <a class="twitter" target="_blank" href="https://www.facebook.com/samatvafoundation12"><i class="bi bi-facebook"></i></a>
                <a class="facebook" target="_blank" href="https://www.instagram.com/samatva_rehabs_pvt_ltd/"><i class="bi bi-twitter-x"></i></a>
                <!-- <a class="instagram"><i class="bi bi-instagram"></i></a> -->
                <!-- <a class="google-plus"><i class="bi bi-skype"></i></a> -->
                <!-- <a class="linkedin"><i class="bi bi-linkedin"></i></a> -->
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->