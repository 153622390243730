<app-navigation></app-navigation>
<!---->
<section id="contact" class="contact custom_top_margin">
  <div class="container" data-aos="fade-up">
    <div class="section-header">
      <h2 class="blue">Contact Us</h2>
    </div>
    <!-- <div class="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                frameborder="0" allowfullscreen>
            </iframe>
        </div> -->
    <div class="row gy-5 gx-lg-5  ">
      <div class="col-lg-4">
        <div class="info">
          <!-- <h3>Get in touch</h3> -->
          <!-- <p>Some content goes here with timings</p> -->
          <div class="info-item d-flex">
            <i class="bi bi-geo-alt flex-shrink-0"></i>
            <div>
              <h4>Location:</h4>
              <p>#20 Sri Krishna Dhama, 3rd Main, 5th Cross Rd, Kenchenahalli, BHEL Layout, Rajarajeshwari Nagar,
                Bengaluru, Karnataka 560098</p>
            </div>
          </div>
          <div class="info-item d-flex">
            <i class="bi bi-envelope flex-shrink-0"></i>
            <div>
              <h4>Email:</h4>
              <p>samatvalearning@gmail.com </p>
              <p>samatvaoffice@gmail.com</p>
            </div>
          </div>
          <div class="info-item d-flex">
            <i class="bi bi-phone flex-shrink-0"></i>
            <div>
              <h4>Call:</h4>
              <p>+91 78295 44422</p>
              <p>+91 78296 44422 </p>
              <p>+91 78926 25828</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 ">
        <form role="form" class="php-email-form ">
          <div class="row">
            <div class="col-md-6 form-group">
              <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
            </div>
            <div class="col-md-6 form-group mt-3 mt-md-0">
              <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
            </div>
          </div>
          <div class="form-group mt-3">
            <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
          </div>
          <div class="form-group mt-3">
            <textarea class="form-control" name="message" placeholder="Message" required></textarea>
          </div>
          <div class="my-3">
            <div class="loading">Loading</div>
            <div class="error-message"></div>
            <div class="sent-message">Your message has been sent. Thank you!</div>
          </div>
          <div class="text-center"><button type="submit">Send Message</button></div>
        </form>
      </div>
    </div>
  </div>
</section>

<!---->
<app-footer></app-footer>