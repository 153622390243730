<app-navigation></app-navigation>
<!---->
<section class="blog custom_top_margin">
    <div class="container" data-aos="fade-up">
        <div class="section-header">
            <h2 class="blue">Celebrations</h2>
        </div>
    </div>
    <!---->
    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2024</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-3.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-4.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-4.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-4.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-5.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-5.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-5.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-6.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-6.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-6.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-7.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-7.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-7.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2024-8.jpg"
            data-responsive="assets/gallery/celebrations/original/2024-8.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2024-8.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2023</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-3.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-4.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-4.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-4.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-6.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-6.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-6.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-7.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-7.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-7.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2023-78.jpg"
            data-responsive="assets/gallery/celebrations/original/2023-78.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2023-78.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2022</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-3.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-4.png"
            data-responsive="assets/gallery/celebrations/original/2022-4.png" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-4.png" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-5.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-5.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-5.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-6.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-6.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-6.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2022-7.jpg"
            data-responsive="assets/gallery/celebrations/original/2022-7.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2022-7.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2021</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2021-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2021-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2021-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2021-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2021-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2021-2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2021-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2021-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2021-3.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2020</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2020-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2020-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2020-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2020-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2020-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2020-2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2020-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2020-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2020-3.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2019</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2019.jpg"
            data-responsive="assets/gallery/celebrations/original/2019.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2019.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2019-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2019-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2019-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2019-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2019-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2019-2.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2018</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2018.jpg"
            data-responsive="assets/gallery/celebrations/original/2018.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2018.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2018-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2018-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2018-2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2018-3.jpg"
            data-responsive="assets/gallery/celebrations/original/2018-3.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2018-3.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2017</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2017.jpg"
            data-responsive="assets/gallery/celebrations/original/2017.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2017.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2017-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2017-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2017-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2017-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2017-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2017-2.jpg" />
        </a>
        <!---->
        <div class="text-left p-2">
            <h2 class="blue"><u>Celebration of 2016</u></h2>
        </div>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2016-1.jpg"
            data-responsive="assets/gallery/celebrations/original/2016-1.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2016-1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2016-2.jpg"
            data-responsive="assets/gallery/celebrations/original/2016-2.jpg" data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2016-2.jpg" />
        </a>
    </lightgallery>

    <!-- <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">

        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/1.jpg" data-responsive="assets/gallery/celebrations/original/1.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/1.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/2.jpg" data-responsive="assets/gallery/celebrations/original/2.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/2.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/3.jpg" data-responsive="assets/gallery/celebrations/original/3.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/3.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/4.jpg" data-responsive="assets/gallery/celebrations/original/4.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/4.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/5.jpg" data-responsive="assets/gallery/celebrations/original/5.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/5.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/6.jpg" data-responsive="assets/gallery/celebrations/original/6.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/6.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/7.jpg" data-responsive="assets/gallery/celebrations/original/7.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/7.jpg" />
        </a>
        
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/8.jpg" data-responsive="assets/gallery/celebrations/original/8.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/8.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/9.jpg" data-responsive="assets/gallery/celebrations/original/9.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/9.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/10.jpg" data-responsive="assets/gallery/celebrations/original/10.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/10.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/11.jpg" data-responsive="assets/gallery/celebrations/original/11.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/11.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/12.jpg" data-responsive="assets/gallery/celebrations/original/12.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/12.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/13.jpg" data-responsive="assets/gallery/celebrations/original/13.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/13.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/14.jpg" data-responsive="assets/gallery/celebrations/original/14.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/14.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/15.jpg" data-responsive="assets/gallery/celebrations/original/15.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/15.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/16.jpg" data-responsive="assets/gallery/celebrations/original/16.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/16.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/17.jpg" data-responsive="assets/gallery/celebrations/original/17.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/17.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/18.jpg" data-responsive="assets/gallery/celebrations/original/18.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/18.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/19.jpg" data-responsive="assets/gallery/celebrations/original/19.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/19.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/20.jpg" data-responsive="assets/gallery/celebrations/original/20.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/20.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/21.jpg" data-responsive="assets/gallery/celebrations/original/21.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/21.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/22.jpg" data-responsive="assets/gallery/celebrations/original/22.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/22.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/23.jpg" data-responsive="assets/gallery/celebrations/original/23.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/23.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/24.jpg" data-responsive="assets/gallery/celebrations/original/24.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/24.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/25.jpg" data-responsive="assets/gallery/celebrations/original/25.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/25.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/26.jpg" data-responsive="assets/gallery/celebrations/original/26.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/26.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/27.jpg" data-responsive="assets/gallery/celebrations/original/27.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/27.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/28.jpg" data-responsive="assets/gallery/celebrations/original/28.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/28.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/29.jpg" data-responsive="assets/gallery/celebrations/original/29.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/29.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/30.jpg" data-responsive="assets/gallery/celebrations/original/30.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/30.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/31.jpg" data-responsive="assets/gallery/celebrations/original/31.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/31.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/32.jpg" data-responsive="assets/gallery/celebrations/original/32.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/32.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/33.jpg" data-responsive="assets/gallery/celebrations/original/33.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/33.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/34.jpg" data-responsive="assets/gallery/celebrations/original/34.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/34.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/35.jpg" data-responsive="assets/gallery/celebrations/original/35.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/35.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/36.jpg" data-responsive="assets/gallery/celebrations/original/36.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/36.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/37.jpg" data-responsive="assets/gallery/celebrations/original/37.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/37.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/38.jpg" data-responsive="assets/gallery/celebrations/original/38.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/38.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/39.jpg" data-responsive="assets/gallery/celebrations/original/39.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/39.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/40.jpg" data-responsive="assets/gallery/celebrations/original/40.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/40.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/41.jpg" data-responsive="assets/gallery/celebrations/original/41.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/41.jpg" />
        </a>
        <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
            data-src="assets/gallery/celebrations/original/42.jpg" data-responsive="assets/gallery/celebrations/original/42.jpg"
            data-sub-html="">
            <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                src="assets/gallery/celebrations/thumbnail/42.jpg" />
        </a>
    </lightgallery> -->


</section>
<!---->
<app-footer></app-footer>