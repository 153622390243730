import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CareersComponent } from './careers/careers.component';
import { BlogComponent } from './blog/blog.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { GalleryComponent } from './gallery/gallery.component';
import { RajarajeshwariNagarComponent } from './branches/rajarajeshwari-nagar/rajarajeshwari-nagar.component';
import { BasaveshwarNagarComponent } from './branches/basaveshwar-nagar/basaveshwar-nagar.component';
import { ElectronicCityComponent } from './branches/electronic-city/electronic-city.component';
import { JpNagarComponent } from './branches/jp-nagar/jp-nagar.component';
import { KoramangalaComponent } from './branches/koramangala/koramangala.component';
import { EventsComponent } from './events/events.component';
import { CelebrationsComponent } from './celebrations/celebrations.component';
import { CollaborationsComponent } from './collaborations/collaborations.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'team',
    component: TeamComponent,
  },
  {
    path:'contactus',
    component: ContactUsComponent
  },
  {
    path:'careers',
    component: CareersComponent
  },
  {
    path:'blogs',
    component: BlogComponent
  },
  {
    path:'what-we-offer',
    component: WhatWeOfferComponent
  },
  {
    path:'gallery',
    component: GalleryComponent
  },
  {
    path:'rajarajeshwari-nagar',
    component: RajarajeshwariNagarComponent
  },
  {
    path:'basaveshwar-nagar',
    component: BasaveshwarNagarComponent
  },
  {
    path:'electronic-city',
    component: ElectronicCityComponent
  },
  {
    path:'jp-nagar',
    component: JpNagarComponent
  },
  {
    path:'koramangala',
    component: KoramangalaComponent
  },
  {
    path:'events',
    component: EventsComponent
  },
  {
    path:'celebrations',
    component: CelebrationsComponent
  },
  {
    path:'collaborations',
    component: CollaborationsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
