import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-collaborations',
  templateUrl: './collaborations.component.html',
  styleUrls: ['./collaborations.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CollaborationsComponent implements OnInit {

  settings = { 
    counter: false,
    plugins: [lgZoom]
  };

  constructor() { }


  ngOnInit(): void {
  }

  
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };
}
