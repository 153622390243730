import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as AOS from 'aos';

@Component({
  selector: 'app-blogs-details',
  templateUrl: './blogs-details.component.html',
  styleUrls: ['./blogs-details.component.scss']
})
export class BlogsDetailsComponent implements OnInit {

  @Input() public user;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    AOS.init();
  }

  passBack() {
    this.activeModal.close(this.user);
    }

}
