<nav class="navbar navbar-expand-lg navbar-light navbar-custom">
    <div class="container-fluid">
  
      <a class="navbar-brand" href="#">
  
        <a  class="navbar-brand text-responsive" href="#">
          <img src="assets/img/Samatva-logo-resized.png" width="150" height="60" class="d-inline-block align-top" alt="">
          <!-- Paripoorna Vidyapeeta Trust -->
          
        </a>
        <!-- <div>
          <p style="font-size: 10px;">( A Unit of Samatva Rehabs Private Limited)</p>
        </div> -->
      </a>
      
      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false">
        <span class="navbar-toggler-icon" ></span>
      </button> -->
      <button class="navbar-toggler" type="button" (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
  
      <div class="collapse navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarColor01">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link navbar_Link active" routerLink="/">Home
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link navbar_Link active" routerLink="/what-we-offer">What We Offer
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link navbar_Link active" routerLink="/team">Meet Our Team
            </a>
          </li>
          <li class="nav-item dropdown">
            <a  class="nav-link  navbar_Link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Branches
            </a>
            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
              <li><a class="dropdown-item" routerLink="/rajarajeshwari-nagar">Rajarajeshwari Nagar (Head Office)</a></li>
              <li><a class="dropdown-item" routerLink="/basaveshwar-nagar">Basaveshwar Nagar</a></li>
              <li><a class="dropdown-item" routerLink="/electronic-city">Electronic City</a></li>
              <li><a class="dropdown-item" routerLink="/jp-nagar">JP Nagar</a></li>
              <li><a class="dropdown-item" routerLink="/koramangala">Koramangala</a></li>

            </ul>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link navbar_Link active" routerLink="/blogs">Events
            </a>
          </li> -->
          <li class="nav-item dropdown">
            <a  class="nav-link  navbar_Link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLinkG" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Gallery
            </a>
            <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLinkG">
              <li><a class="dropdown-item" routerLink="/events">Events</a></li>
              <li><a class="dropdown-item" routerLink="/celebrations">Celebrations</a></li>
              <li><a class="nav-link scrollto cursor" routerLink="/collaborations">Collaborations</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link navbar_Link active" routerLink="/contactus">Contact Us
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

<!-- <header class="header nav_custom_grad" data-scrollto-offset="0">
    <div class="container-fluid d-flex align-items-center justify-content-between">
        <a class="logo d-flex align-items-center scrollto me-auto me-lg-0 text-dec">
            <img src="assets/img/Samatva-logo.png" alt="">
        </a>
                <nav id="navbar" class="navbar ">
            <ul>
                <li><a class="nav-link scrollto cursor" routerLink="/">Home</a></li>
                <li><a class="nav-link scrollto cursor" routerLink="/what-we-offer">What We Offer</a></li>
                <li><a class="nav-link scrollto cursor" routerLink="/team">Meet Our Team</a></li>
                <li class="dropdown">
                    <a class="nav-link scrollto cursor"><span>Branches</span> 
                        <i class="bi bi-chevron-down dropdown-indicator"></i>
                    </a>
                    <ul>
                        <li><a class="nav-link scrollto cursor" routerLink="/rajarajeshwari-nagar">Rajarajeshwari Nagar (Head Office)</a></li>
                        <li><a class="nav-link scrollto cursor" routerLink="/basaveshwar-nagar">Basaveshwar Nagar</a></li>
                        <li><a class="nav-link scrollto cursor" routerLink="/electronic-city">Electronic City</a></li>
                        <li><a class="nav-link scrollto cursor" routerLink="/jp-nagar">JP Nagar</a></li>
                        <li><a class="nav-link scrollto cursor" routerLink="/koramangala">Koramangala</a></li>
                    </ul>
                </li>
                <li><a class="nav-link scrollto cursor" routerLink="/blogs">Events</a></li>
                <li><a class="nav-link scrollto cursor" routerLink="/gallery">Gallery</a></li>
                <li><a class="nav-link scrollto cursor" routerLink="/contactus">Contact Us</a></li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle d-none"></i>
        </nav>
    </div>
</header> -->