import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxGalleryModule } from 'ngx-gallery';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TeamComponent } from './team/team.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CareersComponent } from './careers/careers.component';
import { BlogComponent } from './blog/blog.component';
import { BlogsDetailsComponent } from './blogs-details/blogs-details.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { GalleryComponent } from './gallery/gallery.component';
import { RajarajeshwariNagarComponent } from './branches/rajarajeshwari-nagar/rajarajeshwari-nagar.component';
import { BasaveshwarNagarComponent } from './branches/basaveshwar-nagar/basaveshwar-nagar.component';
import { ElectronicCityComponent } from './branches/electronic-city/electronic-city.component';
import { JpNagarComponent } from './branches/jp-nagar/jp-nagar.component';
import { KoramangalaComponent } from './branches/koramangala/koramangala.component';
//import { Angular2ImageGalleryModule } from 'angular2-image-gallery';
import { LightgalleryModule } from 'lightgallery/angular/10';
import { EventsComponent } from './events/events.component';
import { CelebrationsComponent } from './celebrations/celebrations.component';
import { CollaborationsComponent } from './collaborations/collaborations.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavigationComponent,
    TeamComponent,
    FooterComponent,
    ContactUsComponent,
    CareersComponent,
    BlogComponent,
    BlogsDetailsComponent,
    WhatWeOfferComponent,
    GalleryComponent,
    RajarajeshwariNagarComponent,
    BasaveshwarNagarComponent,
    ElectronicCityComponent,
    JpNagarComponent,
    KoramangalaComponent,
    EventsComponent,
    CelebrationsComponent,
    CollaborationsComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,

    AppRoutingModule,
    //NgxGalleryModule
    //Angular2ImageGalleryModule,
    HammerModule,
    LightgalleryModule

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
