<app-navigation></app-navigation>
<!---->
<section class="services custom_top_margin">
    <div class="container" data-aos="fade-up">
        <div class="section-header mt-0">
            <h3 class="blue">Samatva Clinic for Speech-Language Therapy and Occupational Therapy -  Koramangala</h3>
        </div>
    </div>
        
        <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/koramangala/original/KR_01.JPG"
                data-responsive="assets/img/branches/koramangala/original/KR_01.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/koramangala/thumbnail/KR_01.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/koramangala/original/KR_02.JPG"
                data-responsive="assets/img/branches/koramangala/original/KR_02.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/koramangala/thumbnail/KR_02.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/koramangala/original/KR_03.JPG"
                data-responsive="assets/img/branches/koramangala/original/KR_03.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/koramangala/thumbnail/KR_03.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/koramangala/original/KR_04.JPG"
                data-responsive="assets/img/branches/koramangala/original/KR_04.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/koramangala/thumbnail/KR_04.JPG" />
            </a>
            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/img/branches/koramangala/original/KR_05.JPG"
                data-responsive="assets/img/branches/koramangala/original/KR_05.JPG" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/img/branches/koramangala/thumbnail/KR_05.JPG" />
            </a>
        </lightgallery>

        <div class="row pt-5">
            <div class="col-md-8 text-left">
                <div class="m-3 p-1">
                    <h3 class="blue"><u>Address</u></h3>
                    <p><b>972/A, 2nd floor, S.T. Bed, Koramangala 4th Block, Bengaluru-34
                    </b></p>
                    <p><b>Contact: 829544422 / 7829644422 / 7892625828</b></p>
                </div>
    
            </div>
            <div class="col-md-4">
                <div class="text-center">
                    <img class="img-responsive text-center" width="150"
                    src="assets/img/branches/qrcodes/koramangala.PNG" />
                </div>
            </div>
        </div>
    
</section>
<!---->
<app-footer></app-footer>