<app-navigation></app-navigation>
<!---->
<section class="blog custom_top_margin">
    <div class="container" data-aos="fade-up">
        <div class="section-header">
            <h2 class="blue">Collaborations</h2>
        </div>
        <div class="row g-5">
            
            <div class="col-lg-12">
                <div class="row gy-4 posts-list">
                    <div class="col-lg-4">
                        <article class="d-flex flex-column">
                            <div class="post-img">
                                <img src="assets/gallery/celebrations/thumbnail/2024-.jpg" alt="" class="img-fluid">
                            </div>
                            <h2 class="title">
                                <a href="blog-details.html">
                                    Blog title goes here
                                </a>
                            </h2>
                            <div class="meta-top">
                                <ul>
                                    <li class="d-flex align-items-center"><i class="bi bi-person"></i> <a
                                            href="blog-details.html">Admin</a></li>
                                    <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <a
                                            href="blog-details.html"><time datetime="2022-01-01">Jan 1, 2022</time></a>
                                    </li>
                                </ul>
                            </div>
                            <div class="content">
                                <p>
                                    blog short content goes here, blog short content goes here,
                                    blog short content goes here,
                                </p>
                            </div>
                            <div class="read-more mt-auto align-self-end" (click)="readMoreBlog()">
                                <a style="text-decoration: none; cursor: pointer;">Read More</a>
                            </div>
                        </article>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!---->
<app-footer></app-footer>