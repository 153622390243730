<app-navigation></app-navigation>
<!---->
<section class="blog custom_top_margin">
    <div class="container" data-aos="fade-up">
        <div class="section-header">
            <h2 class="blue">Collaborations</h2>
        </div>

        <!---->
        <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">

            <a data-lg-size="" data-pinterest-text="" data-tweet-text="" class="gallery-item "
                data-src="assets/gallery/events/original_old/04.jpg"
                data-responsive="assets/gallery/events/original_old/04.jpg" data-sub-html="">
                <img class="col-lg-3 col-md-3 col-sm-12 col-xs-12 p-2 pointer img-responsive"
                    src="assets/gallery/events/thumbnail_old/04.jpg" />
            </a>
        </lightgallery>
    </div>
</section>
<!---->
<app-footer></app-footer>