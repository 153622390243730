<app-navigation></app-navigation>
<!---->
<section class="services custom_top_margin" >
    <div class="container-fluid" data-aos="fade-up">
        <div class="section-header mt-0">
            <h2 class="blue">What We Offer</h2>
        </div>
        <!---->
        <!-- <div class="row gy-5 px-5 ">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="row no-gutters">
                        <div class="col-auto">
                            <img src="//placehold.it/200" class="img-fluid" alt="">
                        </div>
                        <div class="col">
                            <div class="card-block px-2 py-4">
                                <a class="stretched-link" style="text-decoration: none;">
                                    <h3 class="blue">Speech Language Therapy</h3>
                                </a>
                                <p>
                                    Speech and language therapy focuses on treatment, support and care for children and adults
                                    who have difficulties with communication or with eating, drinking and swallowing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!---->
        <div class="row gy-5 px-5 ">
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Speech & Language Therapy.jpg" class="img-fluid" alt="">
                    </div>
                    <!--<a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Speech Language Therapy</h3>
                        </a>-->
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Speech Language Therapy</h3>
                        </a>
                        <!-- <p>
                            Speech and language therapy focuses on treatment, support and care for children and adults
                            who have difficulties with communication or with eating, drinking and swallowing.
                        </p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Occupational Therapy.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Occupational Therapy</h3>
                        </a>
                        <!-- <p>
                            Occupational therapy help individuals improve their skills required for independent living
                            which include gross & fine motor skills, sensory skills, eye hand coordination, whole body
                            balance and basic life skills.
                        </p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Physiotherapy.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Physiotherapy</h3>
                        </a>
                        <!-- <p>
                            Physiotherapy aims to restore, maintain and make the most of a patient’s mobility, function
                            and well-being.
                        </p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Group Intervention_School Readiness Program.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Group Intervention/School Readiness Program</h3>
                        </a>
                        <!-- <p>
                            Our group intervention program focus on helping children with unique developmental and
                            behavioral challenges to transition into mainstream schooling.
                        </p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/NIOS.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">National Institute of Open Schooling (NIOS)</h3>
                        </a>
                        <!-- <p>
                            NIOS provide sustainable inclusive learning with universal and flexible access to quality
                            education and skill development for children with unique needs.
                            <a class="link" href="https://www.nios.ac.in/" target="_blank"><i class="bi bi-link "></i></a>
                            
                        </p> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Social Skills Group.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Talk-Tank: Social Skills Group</h3>
                        </a>
                        <!-- <p>
                            Social Skills Group is designed to help kids who have difficulty in socialization, peer play
                            and other pragmatic aspects of language. It includes a range of interventions &
                            instructional methods to assist individuals understand & improve social communication skills
                        </p> -->
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <div class="section-header mt-5" data-aos="fade-up"  data-aos-delay="200">
            <h2 class="blue">Adult Neuro Rehabilitation</h2>
        </div>
        <div class="row gy-2 px-5" data-aos="fade-up"  data-aos-delay="200">
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Adult Speech Therapy.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Speech Language Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Swallow Therapy.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Swallow Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Adult Sensori-motor Therapy.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Sensori-motor Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!---->
        <div class="section-header mt-5" data-aos="fade-up"  data-aos-delay="200">
            <h2 class="blue">Our Unique Therapeutic Approaches</h2>
        </div>
        <div class="row gy-2 px-5" data-aos="fade-up"  data-aos-delay="200">

            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Oral Placement Therapy.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Oral Placement Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/PROMPT.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">PROMPT</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Early Intervention Program.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Early Intervention Program</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Sensory Integration.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Sensory Integration(SI)</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/DIR Floortime.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">DIR Floortime®</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Play Therapy.JPG" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Play Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Feeding therapy.png" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Feeding Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/hanen.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">The Hanen Program®</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Augmentative & Alternative Communication.jpg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Augmentative & Alternative Communication</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 mt-5" data-aos="zoom-in" data-aos-delay="200">
                <div class="service-item">
                    <div class="img">
                        <img src="assets/img/what_we_offer/Auditory Verbal Therapy.jpeg" class="img-fluid" alt="">
                    </div>
                    <div class="position-relative m-3 text-center">
                        <a class="stretched-link" style="text-decoration: none;">
                            <h3 class="blue">Auditory Verbal Therapy</h3>
                        </a>
                    </div>
                </div>
            </div>

            
            
            
            
            
            

            <!-- <div class="col-12">
                <h4 class="blue">Oral Placement Therapy</h4>
                <p>We have SLPs certified with Oral Placement Therapy which is a specialized form of Speech Therapy
                    which is effective to improve speech clarity and feeding. <a class="blue" href="https://talktools.com/pages/what-is-opt" target="_blank">Link</a>
                </p>
            </div>
            <div class="col-12">
                <h4 class="blue">PROMPT</h4>
                <p>Prompts for Restructuring Oral Muscular Phonetic Targets is a unique & holistic tactile/kinesthetic
                    approach to assessment and treatment of speech delays or disorders. <a class="blue" href="https://promptinstitute.com/" target="_blank">Link</a> 
                </p>
            </div>
            
            <div class="col-12">
                <h4 class="blue">Sensory Integration(SI)</h4>
                <p>SI Therapy is a tailored approach used to help children learn to use all their senses together to
                    help in processing, integration, and organisation of sensory information from the body and the
                    environment.</p>
            </div>
            <div class="col-12">
                <h4 class="blue">DIR Floortime®</h4>
                <p>Samatva has therapists who are trained & certified in DIRFloortime® Courses.This approach promotes an
                    individual's development through a respectful, playful, joyful, and engaging process by embracing
                    power of relationships and human connection.
                    <a class="blue" href="https://www.icdl.com/dir/floortime?gclid=Cj0KCQiAgqGrBhDtARIsAM5s0_keax27aagchHhufNaqGVgY52KvAO6BO-f10TKrsbGZ4phOdHnFfr8aAs_eEALw_wcB" target="_blank">Link</a>
                </p>
            </div>
            <div class="col-12">
                <h4 class="blue">Play Therapy</h4>
                <p>At Samatva, we believe play provides the foundation for learning and development. We incorporate
                    principles of Play Therapy by National Play Therapy Association of India in our holistic management
                    approaches. 
                    <a class="blue" href="https://www.playtherapyindia.org" target="_blank">Link</a>
                </p>
            </div>
            <div class="col-12">
                <h4 class="blue">Adult Neuro Rehabilitation</h4>
                <p>Samatva is collaborated with Kshema Hospice, a fully accredited and equipped acute care and
                    rehabilitation specialty hospital to provide neuro-rehab services (including Speech-Language
                    Therapy, Occupational Therapy, Dysphagia Therapy, Coma & Cognitive Stimulation) to individuals with
                    diseases, trauma, or disorders of the nervous system</p>
            </div>
            <div class="col-12">
                <h4 class="blue">Feeding & Swallowing Therapy</h4>
                <p>Therapists at Samatva are equipped with Feeding therapy that involves a series of techniques tailored
                    to make the entire process of eating easier and more enjoyable for children with feeding issues. Our
                    SLPs are also trained to provide specific management for adults with swallowing difficulties.
                </p>
            </div>
            <div class="col-12">
                <h4 class="blue">The Hanen Program®</h4>
                <p>Hanen Programs are evidence and research based, family-centred programs that help families support
                    their child's communication goals. Hanen certified professionals at Samatva provide parental
                    training and workshop through offline & online modalities.
                    <a class="blue" href="https://www.hanen.org/Home.aspx" target="_blank">Link</a>
                </p>
            </div>
            <div class="col-12">
                <h4 class="blue">Augmentative & Alternative Communication</h4>
                <p>Augmentative and alternative communication encompasses the communication methods used to supplement
                    or replace speech or writing for those with impairments in the production or comprehension of spoken
                    or written language.</p>
            </div>
            <div class="col-12">
                <h4 class="blue">Auditory Verbal Therapy</h4>
                <p>Auditory Verbal therapy is a highly specialist, early intervention, family-centred, coaching
                    programme for individuals with hearing difficulties.
                </p>
            </div> -->
        </div>
    </div>
</section>
<!---->
<app-footer></app-footer>